import store from '@/store/index';
const { user } = store.getters;
const btnPermissionsArr = store.getters.btnPermissions;
const EDIT_TYPE = {
    EDIT: 2,
    CANCEL: -3
};
export const config = {
    name: 'accountManage',
    searchFields: [
        {
            name: '客户编码',
            value: 'customerCode',
            isFixed: true,
            type: 'input',
            span: 3
        },
        {
            name: '客户名称',
            value: 'customerName',
            isFixed: true,
            type: 'input',
            span: 3
        },
        {
            name: '账号',
            value: 'accountNumber',
            isFixed: true,
            type: 'input',
            span: 3
        },
        {
            name: '账号状态',
            value: 'accountFlag',
            optionNum: 'CRM_ACCOUNT_FLAG',
            type: 'select',
            isFixed: true,
            span: 3
        },
        {
            name: '创建人',
            value: 'createUserCode',
            type: 'advanceUser',
            span: 4
        },
        {
            name: '创建时间',
            value: 'createTime',
            isFixed: true,
            type: 'time',
            time: 'daterange',
            startPlaceholder: '创建时间从',
            endPlaceholder: '创建时间到',
            span: 5
        },
        {
            name: '手机',
            value: 'customerMobile',
            type: 'input',
            span: 3
        },
        {
            name: '邮箱',
            value: 'customerEmail',
            type: 'input',
            span: 3
        }
    ],
    selection: true,
    index: false,
    columns: [
        {
            label: '客户编码',
            prop: 'customerCode',
            width: 140,
            sort: true,
            mustFill: true
        },
        {
            label: '客户名称',
            width: 160,
            prop: 'customerName',
            type: 'advance',
            mustFill: true,
            advanceConfig: [
                { prop: 'customerName', value: '客户名称' },
                { prop: 'customerCode', value: '客户编码' }
            ],
            tableConfig: [
                { prop: 'accountName', value: '客户名称' },
                { prop: 'customItem232__c', value: '客户编码' },
                { prop: 'customItem169__c', value: '客户简称' },
                { prop: 'accountId', value: '账号ID' }
            ],
            method: 'post',
            prefix: '/api-lcrm',
            advanceUrl: '/contract/customer/account/relation',
            cbParams: [
                'customItem232__c#customerCode',
                'accountName#customerName',
                'customItem169__c#customerNickname'
            ],
            beforeRequest: (params) => {
                params.userCode = user.userCode;
                return params;
            }
        },
        {
            label: '联系人姓名',
            prop: 'outsideCustomerName',
            type: 'input',
            mustFill: true,
            width: 140
        },
        {
            label: '手机',
            prop: 'customerMobile',
            type: 'input',
            mustFill: true,
            width: 140,
            verify: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            slot: true
        },
        {
            label: '邮箱',
            prop: 'customerEmail',
            type: 'input',
            width: 200,
            verify: /^[0-9a-zA-Z._-]+@[0-9a-zA-Z-]+(\.[a-zA-Z]+){1,2}$/,
            slot: true
        },
        {
            label: '账号',
            prop: 'accountNumber',
            width: 140,
            slot: true
        },
        {
            label: '账号名称',
            prop: 'accountName',
            width: 140
        },
        {
            label: '账号有效期',
            prop: 'accountValidityDate',
            sort: true,
            width: 160
        },
        {
            label: '账号类型',
            prop: 'accountType',
            type: 'select',
            optionsKey: 'CRM_ACCOUNT_TYPE', // TODO
            // mustFill: true,
            disabled: true,
            placeholder: '',
            width: 140
        },
        {
            label: '账号状态',
            prop: 'accountFlag',
            type: 'select',
            optionsKey: 'CRM_ACCOUNT_FLAG',
            // mustFill: true,
            disabled: true,
            sort: true,
            placeholder: '',
            width: 140
        },
        {
            label: '账号有效期更新来源',
            prop: 'accountValiditySource',
            width: 200
        },
        {
            label: '账号有效期最近更新时间',
            prop: 'accountUpdateTime',
            sort: true,
            width: 200
        },
        {
            label: '创建人',
            prop: 'createUserName'
        },
        {
            label: '创建时间',
            prop: 'createTime',
            sort: true,
            width: 160
        },
        {
            label: '修改人',
            prop: 'updateUserName'
        },
        {
            label: '修改时间',
            prop: 'updateTime',
            sort: true,
            width: 160
        }
    ],
    actions: { // 表格操作列的配置
        fixedWidth: 160, // fixedWidth（可选） 设置操作列的固定宽度，不加该参数默认是按钮list长度*50px
        list: [
            {
                label: '生成账号',
                event: 'saveRow',
                displayRule: (row) => {
                    if (+row.edit === EDIT_TYPE.EDIT && !row.id && btnPermissionsArr.includes('0808000002')) {
                        return true;
                    }
                }
            },
            {
                label: '启用',
                event: 'enable',
                displayRule: (row) => {
                    if (+row.edit === EDIT_TYPE.CANCEL && +row.accountFlag === 0 && btnPermissionsArr.includes('0808000003')) {
                        return true;
                    }
                }
            },
            {
                label: '停用',
                event: 'disable',
                displayRule: (row) => {
                    if (+row.edit === EDIT_TYPE.CANCEL && +row.accountFlag === 1 && btnPermissionsArr.includes('0808000004')) {
                        return true;
                    }
                }
            }
        ]
    }
};
