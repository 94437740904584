import request from '@/utils/http';
const apiCrmHost = 'api-lcrm';

// 查询账号列表
export const customerAccountPageApi = (data) => {
    return request({
        url: apiCrmHost + '/contract/customer/account/page',
        method: 'post',
        data
    });
};
// 账号新增保存
export const customerAccountAddApi = (data) => {
    return request({
        url: apiCrmHost + '/contract/customer/account/add',
        method: 'post',
        data
    });
};
// 账号启用
export const customerAccountEnableApi = (data) => {
    return request({
        url: apiCrmHost + '/contract/customer/account/enable',
        method: 'post',
        data
    });
};
// 账号停用
export const customerAccountDisableApi = (data) => {
    return request({
        url: apiCrmHost + '/contract/customer/account/disable',
        method: 'post',
        data
    });
};
