<template>
    <div class="account-manage">
        <Paper class="account-manage-paper">
            <list-layout-paper>
                <template slot="header-search" class="searchBox">
                    <search-box
                        ref="searchBox"
                        :search-key="config.name"
                        :fields="config.searchFields"
                        :showResetBtn="true"
                        @search-change="accountList.searchList"
                    />
                </template>
                <template slot="header-button">
                    <lotsButton
                        v-has="authorityCode.addNew"
                        type="primary"
                        size="mini"
                        @click="addNew"
                        title="新增"
                        :disabled="isDisableAdd">
                        <span class="icon iconfont">&#xe66a;</span> 新增
                    </lotsButton>
                    <lotsButton
                        v-has="authorityCode.export"
                        type="primary"
                        size="mini"
                        title="导出">
                        <report-export
                            #default="{ submit }"
                            reportCode="REPORT_CRM_ACCOUNT">
                            <span @click="handleExport(submit)">导出</span>
                        </report-export>
                    </lotsButton>
                    <!-- <el-tooltip effect="dark" :content="'列配置'" placement="bottom">
                        <el-button
                            icon="iconfont icon-list_install"
                            type="primary"
                            size="mini"
                            :title="'列配置'"
                            @click="showColumnConfigDialog"
                            plain />
                    </el-tooltip> -->
                </template>
                <!-- 表格 -->
                <template v-slot:list="{ tableHeight }">
                    <edit-table-box
                        ref="editTableBox"
                        v-loading="accountList.tableLoading"
                        :control="true"
                        :selection="config.selection"
                        :columns="config.columns"
                        :height="tableHeight"
                        :tableData="accountList.totalData"
                        :actions="config.actions"
                        :allowRowClick="false"
                        :key="rendertable"
                        @selection-change="handleSelectionChange"
                        @element-change="handleChangeRow"
                        @input-blur="inputBlur"
                        @btn-event="actionClick">
                        <template v-slot:col-append="{item, row, rIndex}">
                            <div class="col-append-box" v-show="item.prop === 'customerMobile' || item.prop==='customerEmail'">
                                <div class="col-append-content">
                                    {{ row[item.prop] ? ((item.prop === 'customerMobile' ? row.showMobile : row.showEmail) ? row[item.prop] : '****') : ''}}</div>
                                <div class="col-append-btn">
                                    <i
                                        title="查看完整信息"
                                        v-show="row[item.prop] && (item.prop === 'customerMobile' ? !row.showMobile : !row.showEmail)"
                                        class="el-icon-view eye-btn"
                                        :class="{'bigData-btn-viewPhone': item.prop === 'customerMobile', 'bigData-btn-viewEmail': item.prop==='customerEmail'}"
                                        @click="handelView(row, (item.prop === 'customerMobile' ? 'showMobile' : 'showEmail'))"></i>
                                </div>
                            </div>
                            <div class="col-append-box" v-show="item.prop === 'accountNumber'">
                                <div class="col-append-content">
                                    {{ row[item.prop] ? (row.showAccount ? row[item.prop] : '****') : ''}}</div>
                                <div class="col-append-btn">
                                    <i
                                        title="查看完整信息"
                                        v-show="row[item.prop] && !row.showAccount"
                                        class="el-icon-view eye-btn bigData-btn-viewAccount"
                                        @click="handelView(row, 'showAccount')"></i>
                                </div>
                            </div>
                        </template>
                    </edit-table-box>
                </template>
                <template slot="footer">
                    <lots-pagination
                        @size-change="accountList.sizeChange"
                        :current-page.sync="accountList.pageNo"
                        @current-change="accountList.pageChange"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="accountList.pageTotal" >
                    </lots-pagination>
                </template>
            </list-layout-paper>
            <!-- 表格列配置弹框 -->
            <!-- <column-config
                :pageID="config.name"
                :initColumns="config.columns"
                ref="otpColumnConfig"
                @header-change="config.columns = $event"
            /> -->
        </Paper>
    </div>
</template>
<script>
import Paper from '@/components/core/Paper';
import lotsPagination from '@/components/lots/lotsPagination/Index';
import listLayoutPaper from '@/components/lots/listLayout/Paper';
import searchBox from '@/components/lots/searchBox';
import editTableBox from '@/components/lots/editTableBox/Index';
import {
    customerAccountPageApi,
    customerAccountAddApi,
    customerAccountEnableApi,
    customerAccountDisableApi
} from '@mdm/api/accountManage/accountList.js';
import { AdvanceListClass } from '@/modules/mdm/composition/dc/common/list/AdvanceListClass';
import { config as configStatic } from './config';
import { ref, reactive, onMounted, onActivated, computed } from '@vue/composition-api';
import { Message, MessageBox } from 'element-ui';
import utils from '@/components/utils/common.js';
import lotsButton from '@/components/lots/lotsButton';
import reportExport from '@/components/lots/reportExportPlugin';
// import columnConfig from '@/components/lots/columnConfig/Index';
import store from '@/store';
import btnAuthority from '@/modules/mdm/btnAuthority.js';
import _ from 'lodash';

export default {
    name: 'tianfuBankContract',
    components: {
        Paper,
        listLayoutPaper,
        searchBox,
        // tableBox,
        editTableBox,
        lotsPagination,
        lotsButton,
        reportExport
        // columnConfig
    },
    /* eslint-disable max-lines-per-function */
    setup(props, setupContext) {
        const authorityCode = reactive(btnAuthority.accountManage);
        const { user } = store.getters;
        const tenantCode = store.getters.currentTenant.tenantCode;
        class AccountListClass extends AdvanceListClass {
            beforeGetList(condition) {
                return searchDataFormat(condition, { tenantCodes: tenantCode });
            };
            afterGetList (res) {
                if (+res.code === 0) {
                    this.totalData.value = res.data.list.map(v => {
                        v.accountFlag = v.accountFlag + '';
                        v.edit = -3;
                        v.allowEdit = !v.id;
                        v.accountFlag = v.accountFlag || v.accountFlag === 0 ? v.accountFlag + '' : '';
                        v.saveRowLoading = false;
                        v.enableLoading = false;
                        v.disableLoading = false;
                        return v;
                    });
                    this.pageTotal.value = res.data.totalCount;
                }
            };
        }
        const accountList = new AccountListClass({
            Api: {
                listApi: customerAccountPageApi // 查询接口
            }
        });
        const searchDataFormat = (condition = {}, params = {}) => {
            if (condition.createTime && condition.createTime.length) {
                condition.createStartTime = utils.formatDateTime(condition.createTime[0]);
                condition.createEndTime = utils.formatDateTime(condition.createTime[1]);
                Reflect.deleteProperty(condition, 'createTime');
            }
            if (condition.accountFlag) {
                condition.accountFlag = +condition.accountFlag;
            }
            if (condition.selectList && condition.selectList.length) {
                condition.createUserCode = condition.selectList[0].userCode;
            }
            Reflect.deleteProperty(condition, 'selectList');
            Reflect.deleteProperty(condition, 'userName');
            condition.userCode = user.userCode;
            Object.keys(condition).forEach(v => {
                !condition[v] && condition[v] !== 0 && (condition[v] = undefined);
            });
            return { ...condition, ...params };
        };
        const config = reactive(configStatic);
        const holePageLoading = ref(false);
        const selectedList = ref([]);
        const handleSelectionChange = (data) => {
            selectedList.value = data;
        };
        const otpColumnConfig = ref(null);
        const showColumnConfigDialog = () => {
            otpColumnConfig.value.show(true);
        };
        // 双击表格
        const rowDBclick = (row, pageType = '') => {
            // 滚动到顶部
        };
        const mobileReg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
        const emailReg = /^[0-9a-zA-Z._-]+@[0-9a-zA-Z-]+(\.[a-zA-Z]+){1,2}$/;
        const actionClick = (row, event, index) => {
            if (event === 'saveRow') {
                const flag = setupContext.refs.editTableBox.saveItemData(row);
                if (!flag) { return false; }
                const params = {
                    customerName: row.customerName,
                    customerCode: row.customerCode,
                    outsideCustomerName: row.outsideCustomerName,
                    customerMobile: row.customerMobile,
                    customerEmail: row.customerEmail,
                    customerNickname: row.customerNickname
                };
                row['saveRowLoading'] = true;
                customerAccountAddApi(params).then(res => {
                    if (+res.code === 0) {
                        Message.success('创建成功，账号密码已通过短信发送至联系人手机');
                        accountList.getList();
                    }
                }).finally(() => {
                    row['saveRowLoading'] = false;
                });
            }
            if (event === 'enable') {
                row['enableLoading'] = true;
                customerAccountEnableApi({ id: row.id }).then(res => {
                    if (+res.code === 0) {
                        Message.success('启用成功');
                        accountList.getList();
                    }
                }).finally(() => {
                    row['enableLoading'] = false;
                });
            }
            if (event === 'disable') {
                MessageBox.confirm('是否确认要停用?', '提示', {
                    confirmButtonText: '确认',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    row['disableLoading'] = true;
                    customerAccountDisableApi({ id: row.id }).then(res => {
                        if (+res.code === 0) {
                            Message.success('停用成功');
                            accountList.getList();
                        }
                    }).finally(() => {
                        row['disableLoading'] = false;
                    });
                });
            }
        };
        const addNew = () => { // 新增合同信息
            // setupContext.refs.editTableBox.addRow();
            accountList.totalData.value.unshift({
                saveRowLoading: false,
                enableLoading: false,
                disableLoading: false,
                edit: 2,
                allowEdit: true
            });
            const timer = setTimeout(() => { // 解决表格错位
                setupContext.refs.editTableBox.$refs.moduleTable.doLayout();
            });
            timer && clearTimeout(timer);
        };
        const handleChangeRow = ({ prop, val, type, row, index }) => {
            if (type === 'advance') {
                // rendertable.value = !rendertable.value; // 解决客户编码不显示的bug
            }
        };
        const inputBlur = ({ prop, val, row }) => {
            if (prop === 'customerMobile') {
                if (val && !mobileReg.test(val)) {
                    // row.customerMobile = '';
                    Message.warning('输入的手机号格式不正确');
                }
            }
            if (prop === 'customerEmail') {
                if (val && !emailReg.test(val)) {
                    // row.customerEmail = '';
                    Message.warning('输入的邮箱号格式不正确');
                }
            }
        };
        const isDisableAdd = computed(() => {
            return accountList.totalData.value.some(item => item.edit === 2);
        });
        const handleExport = async(submit) => {
            await setupContext.refs.searchBox.submitSearch(); // 解决输入参数不生效问题
            const { value } = accountList.searchModel;
            const newData = _.cloneDeep(value); // 解决搜索参数被清空的问题
            const params = {
                ...searchDataFormat(newData),
                deleteFlag: 0
            };
            Object.keys(params).forEach(v => {
                if (params[v] === undefined || params[v] === null || params[v] === '') {
                    delete params[v];
                }
            });
            submit(params);
        };
        const rendertable = ref(true);
        const handelView = (row, prop) => {
            row[prop] = true;
            rendertable.value = !rendertable.value;
        };
        onActivated(() => {
            setupContext.refs.editTableBox.$refs.moduleTable.doLayout(); // 解决表格错位
        });
        onMounted(() => {
            const queryParams = {
                createTime: utils.defaultDate('365')
            };
            setupContext.refs.searchBox.setQueryParams(queryParams);
            setupContext.refs.searchBox.submitSearch();
        });
        return {
            accountList,
            config,
            selectedList,
            handleSelectionChange,
            otpColumnConfig,
            showColumnConfigDialog,
            rowDBclick,
            actionClick,
            addNew,
            holePageLoading,
            utils,
            authorityCode,
            isDisableAdd,
            handleChangeRow,
            inputBlur,
            handleExport,
            handelView,
            rendertable
        };
    }
};
</script>
<style lang="less">
.account-manage {
    width: 100%;
    .account-manage-paper {
        height: 100%;
    }
    .list-layout-wrapper {
        height: 100%;
    }

    .el-tabs {
        width: 100%;
    }

    .flex-layout .list-main {
        overflow: auto;
    }

    .flex-layout .table {
        overflow-y: auto;
    }

    .layout-content.account-manage-paper {
        padding: 0px 0px 0px;
    }
    .col-append-box {
        display: flex;
        align-items: center;
        .col-append-btn {
            padding-bottom: 3px;
            padding-left: 3px;
            .eye-btn {
                display: inline-block;
                width: 10px;
                height: 10px;
                cursor: pointer;
            }
        }
    }
}
</style>
